body {
  background-color: rgb(234, 234, 234) !important;
}

#root {
    display: flex;
    flex-direction: column;   
    height: 100%;
}

.mainContent {
    flex: 1 0 auto;
    padding-bottom: 50px;
}
.footer {
    flex-shrink: 0;
    background-image: linear-gradient(
            135deg,
            rgb(24, 42, 115) 0%,
            rgb(33, 138, 174) 69%,
            rgb(32, 167, 172) 89%
    );
    padding: 1rem;
    color: white;
}


.ui.inverted.top.fixed.menu {
  background-image: linear-gradient(
          135deg,
          rgb(24, 42, 115) 0%,
          rgb(33, 138, 174) 69%,
          rgb(32, 167, 172) 89%
  ) !important;
}

/*home page styles*/

.masthead {
    display: flex;
    align-items: center;
    background-image: linear-gradient(
            135deg,
            rgb(24, 42, 115) 0%,
            rgb(33, 138, 174) 69%,
            rgb(32, 167, 172) 89%
    ) !important;
    height: 100vh;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
    margin-left: 0.5em;
}

.masthead h1.ui.header {
    font-size: 4em;
    font-weight: normal;
}

.masthead h2 {
    font-size: 1.7em;
    font-weight: normal;
}

/*end home page styles*/
